import code0 from 'url:./code0.txt';
import code1 from 'url:./code1.txt';

fetch(code0)
    .then((r) => r.text())
    .then((t) => {
        document.getElementById('code0').innerHTML = t;
    });

fetch(code1)
    .then((r) => r.text())
    .then((t) => {
        document.getElementById('code1').innerHTML = t;
    });
